<!--
No template as we are using a render function.
Renders contents menu in an <aside> tag.
-->

<script>
  // get some convenience functions e.g. displayDate and convertToHTML
  import misc from '../mixins/misc'
  import {mapGetters, mapActions, mapMutations} from 'vuex'


  export default {
    name: 'BookContents',
    mixins: [misc],
    props: {
      bookname: String //for an individual item we only look at the slug to find it
    },
    // data: function () {
    //   return {
    //     showContents: true, //NB v-if uses this to hide contents list
    //   }
    // },

    created() {
      this.$log.debug(`{BookContents} getBookContents called for book ${this.bookname}`);
      this.getBookContents(this.bookname);
    },

    computed: {
      // mix this into the outer object with the object spread operator
      ...mapGetters('books', [
        'bookContents',
        'currentPage',
        'showContents'
      ])
    },

    methods: {

      ...mapActions('books', [
        'getBookContents' //get a books contents list
      ]),

      ...mapMutations('books', [
        'toggleShowContents' //sets/clears display flag for a book contents list
      ]),

      /**
       * generateLevel - utility method to assist render function by generating a single contents
       * item. NB this is a recursive function.
       *
       * @param createElement
       * @param lines
       * @param currentPage
       * @return {*}
       */
      generateLevel: function (createElement, lines, currentPage, showContents) {
        this.$log.debug('{generateLevel} currentPage:', currentPage, {});
        this.$log.debug('{generateLevel} showContents:', showContents, {});
        let that = this; //closure for use within the map callback

        // create all the <li> items
        let linesArray = lines.map(function (line) { // returns a nested array
            let childrenArray = [];
            if (line.children && line.children.length > 0) {
              childrenArray = that.generateLevel(createElement, line.children, currentPage, showContents);
            }
            //create a list element for this line and all children lines
            return createElement('li',
              [
                createElement('routerLink',
                  {
                    attrs: {
                      'to': '/book/' + that.bookname +'/' + line.slug,
                      'class': currentPage === line.slug ? 'is-active' : ''
                    },
                  },
                  line.title,
                ),
                childrenArray
              ]
            );
          }
        );

        // wrap the <li>'s into a <ul>
        let op = createElement('ul',
          {
            'class': 'menu-list',
            // use the built in v-show directive to toggle the menu for mobiles
            directives: [
              {
                name: 'show',
                value: showContents
              },
            ],
          },
          linesArray
        );
        // that.$log.debug('{generateLevel} op:', op, {});
        return op;
      },
    },

    /**
     * render - render function for component
     *
     * @param createElement
     * @return {VNode}
     */
    render(createElement) {
      // get the contents list etc. from vuex store
      const cl = this.bookContents(this.bookname);
      const cp = this.currentPage(this.bookname);
      const sc = this.showContents(this.bookname);
      // this.$log.debug('{createElement} cl:', cl, {});
      // this.$log.debug('{createElement} cp:', cp, {});
      this.$log.debug('{createElement} sc:', sc, {});
      const contents = createElement('aside', {
          attrs: {
            'class': 'menu'
          },
        },
        [createElement('p', {
            attrs: {
              'class': 'menu-label hand'
            },
            on: {
              click: event =>  {
                // Stop event bubbling once it gets to us
                if (event.target === event.currentTarget) {
                  event.stopPropagation(); //and stop anything above acting on this event
                }
                this.toggleShowContents(
                  {
                    book: this.bookname,
                    showContents: !sc
                  }
                );
              }
            }
          },
          [
          createElement('font-awesome-icon', {
            attrs: {
              'size': '1x',
            },
            class: {
              'has-text-primary': true
            },
            props: {
              // class: 'has-text-primary',
              // size: '1x',
              icon: ['fal','compress-alt']
            },
            directives: [
              {
                name: 'show',
                value: sc
              },
            ],
          }),
          createElement('font-awesome-icon', {
            attrs: {
              'size': '1x',
            },
            class: {
              'has-text-primary': true
            },
            props: {
              // class: 'has-text-primary',
              // size: '1x',
              icon: ['fal','expand-alt']
            },
            directives: [
              {
                name: 'show',
                value: !sc
              },
            ],
          }),
            ' Contents'
          ]),
          this.generateLevel(createElement, cl, cp, sc)
        ]
      );
      return contents;
    }

  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  /*@import "~bulma/sass/utilities/mixins";*/
  /*@import "@/scss/_bulmaOverridden";*/

</style>
